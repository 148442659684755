import * as React from 'react';
import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import 'whatwg-fetch';

import { Location } from '../types/Location';
import LocationsViewer from './LocationsViewer';

const getLocationsApiUrl = (
  cityId: string | undefined,
  countryCode: string | undefined,
): string => {
  if (cityId && countryCode) {
    return `/api/locations?city_id=${cityId}&country_code=${countryCode}`;
  } else if (countryCode) {
    return `/api/locations?country_code=${countryCode}`;
  } else {
    return '/api/locations';
  }
};

const App: React.FC = (props: {
  cityId: string | undefined;
  defaultLatitude: number;
  defaultLongitude: number;
  defaultZoom: number;
  countryCode: string | undefined;
  showMichelinGuideFilter: boolean;
}) => {
  const {
    cityId,
    defaultLatitude,
    defaultLongitude,
    defaultZoom,
    countryCode,
    showMichelinGuideFilter,
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [locations, setLocations] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const url = getLocationsApiUrl(cityId, countryCode);

      const response = await fetch(url, {
        headers: {
          accept: 'application/json; charset=utf-8',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLocations(data.locations as Location[]);
        setIsLoading(false);
      } else {
        setErrorMessage('Error fetching locations');
        setIsLoading(false);
      }
    }

    fetchData();
  }, [cityId, countryCode]);

  return (
    <BrowserRouter>
      <div className="container">
        {isLoading && <span className="loading-indicator">Loading...</span>}
        {errorMessage && <span className="error-message">⚠️ {errorMessage}</span>}
        {!isLoading && (
          <LocationsViewer
            defaultLatitude={defaultLatitude}
            defaultLongitude={defaultLongitude}
            defaultZoom={defaultZoom}
            locations={locations}
            showCityFilter={!cityId}
            showMichelinGuideFilter={showMichelinGuideFilter}
          />
        )}
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  cityId: PropTypes.string,
  defaultLatitude: PropTypes.number.isRequired,
  defaultLongitude: PropTypes.number.isRequired,
  defaultZoom: PropTypes.number.isRequired,
  countryCode: PropTypes.string,
  showMichelinGuideFilter: PropTypes.bool.isRequired,
};

export default App;
