import * as PropTypes from 'prop-types';

export const LocationShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address_line_1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  city_id: PropTypes.string.isRequired,
  city_name: PropTypes.string.isRequired,
  cuisine_id: PropTypes.string.isRequired,
  cuisine_name: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  michelin_guide_2022_rating: PropTypes.string,
  website_url: PropTypes.string,
  is_new: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  included_in_initial_import: PropTypes.bool.isRequired,
  deleted_at: PropTypes.string,
});

export const MappableLocationShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address_line_1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  city_id: PropTypes.string.isRequired,
  city_name: PropTypes.string.isRequired,
  cuisine_id: PropTypes.string.isRequired,
  cuisine_name: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  michelin_guide_2022_rating: PropTypes.string,
  website_url: PropTypes.string,
  is_new: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  included_in_initial_import: PropTypes.bool.isRequired,
  deleted_at: PropTypes.string,
});

export interface Location {
  id: string;
  name: string;
  address_line_1: string;
  postcode: string;
  city_id: string;
  city_name: string;
  cuisine_id: string;
  cuisine_name: string;
  latitude: number | null;
  longitude: number | null;
  michelin_guide_2022_rating: string | null;
  website_url: string | null;
  is_new: boolean;
  created_at: string;
  included_in_initial_import: boolean;
  deleted_at: string;
}

export interface MappableLocation extends Location {
  latitude: number;
  longitude: number;
}
