import * as React from 'react';
import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Map } from 'leaflet';
import { Location, MappableLocation, MappableLocationShape } from '../types/Location';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';

const LocationMap = (props: {
  defaultLatitude: number;
  defaultLongitude: number;
  defaultZoom: number;
  locations: MappableLocation[];
  onSelectLocation: (MappableLocationShape) => void;
  selectedLocation: Location;
}) => {
  const {
    defaultLatitude,
    defaultLongitude,
    defaultZoom,
    locations,
    onSelectLocation,
    selectedLocation,
  } = props;

  const [map, setMap] = useState<Map>();

  useEffect(() => {
    if (
      selectedLocation &&
      map &&
      selectedLocation.latitude &&
      selectedLocation.longitude
    ) {
      map.setView([selectedLocation.latitude, selectedLocation.longitude], 13);
    }
  }, [selectedLocation, map]);

  return (
    <div className="map-container">
      <MapContainer
        center={[defaultLatitude, defaultLongitude]}
        zoom={defaultZoom}
        ref={setMap}
        scrollWheelZoom={false}
        style={{ height: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location) => (
          <Marker
            eventHandlers={{
              click: () => {
                if (map) map.setView([location.latitude, location.longitude], 13);
                onSelectLocation(location);
              },
            }}
            position={[location.latitude, location.longitude]}
            key={location.id}
            opacity={location.deleted_at ? 0.3 : 1}
          >
            <Tooltip>{location.name}</Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

LocationMap.propTypes = {
  defaultLatitude: PropTypes.number.isRequired,
  defaultLongitude: PropTypes.number.isRequired,
  defaultZoom: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(MappableLocationShape).isRequired,
  onSelectLocation: PropTypes.func.isRequired,
};

export default LocationMap;
